import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { Message } from "element-ui";
import store from "@/store/index.js";

export async function initialWeb3(walletApp) {
  try {
    const providerOptions = {
      /* See Provider Options Section */
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "0c912dfca42e4fecb5d854bed971519f",
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });
    window.web3Modal = newWeb3Modal;

    let newWeb3ModalInstance;
    newWeb3ModalInstance = await newWeb3Modal.connectTo(walletApp);
    
    window.web3ModalInstance = newWeb3ModalInstance;
    watchWeb3Event();
    const newWeb3Provider = new ethers.providers.Web3Provider(newWeb3ModalInstance, 'any');
    window.web3Provider = newWeb3Provider;
    return "connected";
  } catch (error) {
    if (error.toString().includes("No Web3 Provider found")) {
      return "no web 3 provider"
    } else {
      // Message({
      //   message: error.toString(),
      //   type: "error",
      //   duration: 3 * 1000,
      // });
      return "error"
    }
  }
}

export async function getCurrentWallet() {
  const address = await window.web3Provider.send("eth_accounts", []);
    store.commit("order/WALLET_ADDRESS", address[0]);
}

//获取领取数量
export function logout() {
  if (window.web3Modal) {
    console.log("clean cache watch")
    window.web3Modal.clearCachedProvider();
    window.web3Modal.off();
  }
  if (window.web3ModalInstance) {
    window.web3ModalInstance.removeAllListeners()
  }
  if (window.web3Provider) {
    window.web3Provider.removeAllListeners();
    window.web3Provider.off();
  }
  // localStorage.setItem("wallet_address", "");
  store.commit("order/WALLET_ADDRESS", "");
  // localStorage.setItem("walletconnect", "");
}

export function watchWeb3Event() {
  window.web3ModalInstance.on("disconnect", (res) => {
    console.log("disconnect", res);
  });
  window.web3ModalInstance.on("connect", function (res) {
    console.log("connect", res);
  });
  window.web3ModalInstance.on("accountsChanged", function (res) {
    console.log("accountChanged", res);
    if (res[0]) {
      store.commit("order/WALLET_ADDRESS", res[0]);
    } else {
      store.commit("order/CURRENTACCOUNT", '');
      // localStorage.setItem("wallet_address", "");
      store.commit("order/WALLET_ADDRESS", "");
    }
  });
  window.web3ModalInstance.on("chainChanged", function (res) {
    console.log(`chainChanged`, res);
    // localStorage.setItem("chain_id", res);
    // const network = parseInt(res, 16);
  });
  window.web3ModalInstance.on("message", (msg) => console.log(`message`, msg));
}