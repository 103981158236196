<template>
  <div class="home">
    <div class="page_pc">
      <div class="content" ref="content">
        <!-- home -->
        <div class="section1 section loading">
          <div class="contain">
            <div class="containin">
              <div class="loadingsec" v-show="loadingShow">
                <div class="bg1" ref="bg1"></div>
                <div class="bg2" ref="bg2"></div>
                <div class="loadingLogo">
                  <div class="rotateLoad" ref="rotateLoad">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/logo_top.png"
                      class="logo_top"
                      ref="logoTop"
                    />
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/logo_bt.png"
                      class="logo_bt"
                      ref="logoBt"
                    />
                  </div>
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/logo_con.png"
                    class="logo_con"
                    ref="logoCon"
                  />
                  <!-- <div class="op" ref="opCon">Loading<span>...</span></div> -->
                </div>
              </div>
              <div class="logo" ref="logo">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/logo.png"
                />
              </div>
              <div class="banner" ref="banner">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/feiren.gif"
                />
              </div>
              <div class="lefticon" ref="lefticon">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/lefticon.png"
                />
              </div>
              <div class="btn" ref="btn">
                <div class="left">
                  <!-- <div class="op">{{ walletAddress }}</div> -->
                  <div class="max">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/maxtext.png"
                    />
                  </div>
                </div>
                <div class="op" v-if="$store.state.order.walletAddress">
                  {{ walletAddress }}
                </div>
                <div
                  class="right rightcon"
                  v-if="!walletConnect"
                  @click="metaMaskConnect"
                >
                  CONNECT WALLET
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/icon_page1.png"
                    class="oimg"
                  />
                </div>
                <div class="right" @click="checkbox" v-else>
                  MINT
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/icon_page1.png"
                    class="oimg"
                  />
                </div>
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/page1_btn.png"
                  class="iconpage"
                />
              </div>
              <div class="meun" ref="meun">
                <!-- <div class="meau_logo">
                  <div class="nav">navigation</div>
                </div> -->
                <div class="meau_list" ref="meauList">
                  <div class="list">HOME</div>
                  <div class="list" @click="goPage(3)">Artist</div>
                  <div class="list" @click="goPage(5)">Project</div>
                  <div class="list" @click="goPage(7)">Roadmap</div>
                  <div class="list" @click="goPage(9)">Curator</div>
                  <div class="list" @click="opensea">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/opensea.png"
                    />
                  </div>
                  <div class="list" @click="twitter">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/twitter.png"
                    />
                  </div>
                  <div class="list" @click="discard">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/discard.png"
                    />
                  </div>
                </div>
                <div class="nav_logo" ref="nav" @click="navLogo">
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/nav_logo.png"
                  />
                </div>
                <div class="zhe"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="section2 section hide">2</div>
        <!-- Artist -->
        <div class="section3 section" ref="section3" id="page3">
          <div class="contain">
            <div class="con">
              <div class="con1">
                Ouyang Ru, an urban artist that mainly works on painting,
                sculpture,<br />and installation art. Most of his artworks are
                expressed by his <br />signature colors and the Loony Face IP
                image, reflecting society<br />and humanity with strong satire
                and humor. Expressing his <br />attitudes through arts, Ouyang
                maintains good<br />cooperation with many brands and ventures.
              </div>
              <div class="con2">
                <div
                  class="con2in"
                  @mouseover="mousehovers(1)"
                  @mouseleave="mouseleaves(1)"
                >
                  <div class="tip">6+</div>
                  <p>Years</p>
                  <div class="hover" v-show="hover1">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/hover1.png"
                    />
                  </div>
                </div>
                <div
                  class="con2in"
                  @mouseover="mousehovers(2)"
                  @mouseleave="mouseleaves(2)"
                >
                  <div class="tip">15+</div>
                  <p>Brands Collabs</p>
                  <div class="hover" v-show="hover2">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/hover2.png"
                    />
                  </div>
                </div>
                <div
                  class="con2in"
                  @mouseover="mousehovers(3)"
                  @mouseleave="mouseleaves(3)"
                >
                  <div class="tip">25+</div>
                  <p>Art Exhibitions</p>
                  <div class="hover hover3" v-show="hover3">
                    <img
                      src="https://img.ohdat.io/loony_face/website/assets/hover3.png"
                    />
                  </div>
                </div>
              </div>
              <img
                src="https://img.ohdat.io/loony_face/website/assets/page2banner.png"
                class="banner"
              />
            </div>
          </div>
          <div class="mask" id="mask"></div>
        </div>
        <div class="section4 section hide"></div>
        <!-- Project -->
        <div class="section5 section" id="page5">
          <div class="contain">
            <div class="con">
              <div class="con_t">
                Loony Face is an IP created by urban artist Ouyang Ru. Making
                impressions with its humorous, sarcastic image, Loony Face has
                made in-depth cooperation with 20+ renowned brands including
                Adidas, Starbucks, Universal Studios, and Absolut Vodka. The art
                piece reflects society and humanity and can be served as a
                portrait of a group of reckless "ordinary people" living in this
                era.
              </div>
              <div class="con_list">
                <div class="list">
                  <div class="tit">Sarcastic</div>
                  <div class="cont">
                    A playful interpretation of reality.<br />The audience can
                    freely interpret the<br />metaphors behind the visual
                    language,<br />or just get sensory enjoyment from<br />the
                    silly yet insightful art style.
                  </div>
                </div>
                <div class="list">
                  <div class="tit">Rebellious</div>
                  <div class="cont">
                    Anti-conventional, anti-order. In this world,<br />
                    there are no restrictions of laws and the <br />so-called
                    norms, because Loony Faces pursue<br />
                    freedom and truth behind all boundaries.
                  </div>
                </div>
                <div class="list">
                  <div class="tit">symbolistic</div>
                  <div class="cont">
                    Loony Face has integrated the symbols of<br />the times into
                    artistic creation, scribbling<br />down the absurdity and
                    greatness of our era.
                  </div>
                </div>
                <div class="list">
                  <div class="tit">Dramatic</div>
                  <div class="cont">
                    By symbolizing the plight of the real world, the<br />Loony
                    City is a chaotic virtual city that is parallel<br />to the
                    real world, and what happens in reality is<br />being staged
                    in the form of a farce.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section6 section hide"></div>
        <!-- lonnycity -->
        <div class="section7 section" id="page7">
          <div class="contain">
            <div class="secin">
              <div class="consec">
                <div class="tit">loony city</div>
                <div class="cons">
                  All holders of Loony Face are legal citizens of Loony City.
                  Interspersing at the boundary of the virtuality and reality,
                  all citizens are both the audiences and the actors, creating a
                  fictional utopia by themselves, for themselves.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section8 section hide"></div>
        <!-- About -->
        <div class="section8 section" id="page9">
          <div class="contain">
            <div class="about">
              <div class="bg">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/bg.png"
                />
              </div>
              <div class="main">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/aboutbanner.png"
                  class="aboutbanner"
                />
                <div class="main_l">Curator</div>
                <div class="main_r">
                  <div class="top">
                    ESTABLISHED IN 2021, OHDAT LABS INITIATES AND PROMOTES THE
                    TRANSFER TO THE WEB3 WORLD OF RENOWNED WEB2 IPS (I.E. PEKING
                    MONSTERS, PRODUCER C). BRIDGING THE PAST AND THE FUTURE, THE
                    VIRTUALITY AND THE REALITY, OHDAT SUCCESSFULLY SECURED $4
                    MILLION IN FUNDING WITHIN 8 MONTHS.
                  </div>
                  <div class="btlist">
                    <div class="listin">
                      <div class="tip">Web3 Strategy</div>
                      <div class="cont">
                        Professional Web3 strategy based on the concept of
                        story-verse, including art, roadmap,marketing,
                        community, tokenomics, etc.
                      </div>
                    </div>
                    <div class="listin">
                      <div class="tip">Community Building</div>
                      <div class="cont">
                        Maintain a certain amount of community engagement on
                        Twitter and Discord with the development of Discord bot.
                      </div>
                    </div>
                  </div>
                  <div class="footlist">
                    <div class="tip">Technological Development</div>
                    <div class="cont">
                      Develop on-chain strategy and deploy safe and innovative
                      smart contracts. Based on project theme, concept and
                      lore,<br />
                      develop NFT websites with creative design.
                    </div>
                  </div>
                  <div class="ohdat">
                    <div class="ohdat_l">
                      <div
                        class="terms"
                        @click="ohdat"
                        style="text-decoration: underline"
                      >
                        https://www.ohdat.io
                      </div>
                      <div
                        @click="terms"
                        class="terms"
                        style="text-decoration: underline"
                      >
                        Terms and Conditions
                      </div>
                      <div>
                        2023 Curated by OHDAT Labs. All rights reserved.
                      </div>
                    </div>
                    <div class="ohdat_r" @click="ohdat">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/ohdat.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll" ref="scroll"></div>
      <div class="tanbox" v-show="boxShow">
        <div class="logo">
          <img src="https://img.ohdat.io/loony_face/website/assets/logo.png" />
        </div>
        <div class="yellow" ref="yellow"></div>
        <div class="boxin" ref="boxin">
          <div class="close" @click="close">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/close.png"
            />
          </div>
          <img
            src="https://img.ohdat.io/loony_face/website/assets/tanbox1.png"
            class="topicon"
          />
          <img
            src="https://img.ohdat.io/loony_face/website/assets/tanbox2.png"
            class="bticon"
          />
          <img
            src="https://img.ohdat.io/loony_face/website/assets/police.png"
            class="police"
          />
          <img
            src="https://img.ohdat.io/loony_face/website/assets/slice.png"
            class="slice"
          />
          <img
            src="https://img.ohdat.io/loony_face/website/assets/pc.gif?tr=w-250,h-250"
            class="download"
            @click="downLoad"
          />
          <div class="title">
            Fan Art Contest #1:<br />
            Stickers Invasion
          </div>
          <div class="contents">
            <div class="con_l">
              <div class="ops">
                The world is a boring place, but we will make it interesting by
                adding some little twists.
              </div>
              <div class="ops_se">
                <div class="tip">
                  How to Enter
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/small.png"
                    class="flower"
                  />
                </div>
                <div class="cont">
                  <p>
                    1. Use the sticker sheets below and put the stickers
                    anywhere you want.
                  </p>
                  <p>
                    2. Be as silly and creative as you want. Photoshop is
                    allowed.
                  </p>
                  <p>3. Post you work on Twitter</p>
                  <p>4. Tag</p>
                  <p>@LOONY_FACE_NFT <br />and #loonyface</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tanbox mintbox" v-show="mintShow" @click="docSel">
        <div class="logo">
          <img src="https://img.ohdat.io/loony_face/website/assets/logo.png" />
        </div>
        <div class="leftFoot" ref="leftFoot" v-show="indx === 0">
          <div class="tip">Check if GENESIS has been used:</div>
          <div class="inpt">
            <input
              type="number"
              placeholder="ENTER GENESIS ID"
              v-model="val"
              @input="changeNumber"
            />
            <div class="enter" @click="enterCheck(val)">CHECK</div>
          </div>
          <div class="exper">
            <img :src="statusIcon" class="close" v-if="statusIcon" />
            {{ statusText }}
          </div>
        </div>
        <div class="yellow" ref="yellow"></div>
        <div class="boxin" ref="boxin">
          <div class="close" @click="close">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/close.png"
            />
          </div>

          <img
            src="https://img.ohdat.io/loony_face/website/assets/police.png"
            class="police"
          />
          <img
            src="https://img.ohdat.io/loony_face/website/assets/slice.png"
            class="sliced"
          />
          <div class="title" v-show="indx === 0" ref="title1">
            The Loony Face: GENESIS you are using is #{{ id }}
          </div>
          <div class="title title1" v-show="indx === 1" ref="title2">
            Mint Loony Face: Main Collection
          </div>
          <div class="tab">
            <div
              :class="index === indx ? 'lab current' : 'lab'"
              v-for="(item, index) in tabText"
              :key="index"
              @click="tabChange(index)"
              :style="
                index === 0
                  ? 'border-radius:10px 0 0 0;'
                  : 'border-radius:0 0 0 10px;border-top:none;'
              "
            >
              {{ item }}
            </div>
          </div>
          <div v-if="showWhite">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/topbanner2.png"
              class="topicons"
              v-if="indx === 1"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/small.png"
              class="flower"
            />
            <div class="yestart" v-show="rarity == 1 && indx == 1">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/topbanner.png"
                class="nulltop"
              />
              <div class="margin yestartcon">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/small.png"
                  class="flower"
                />
                <video
                  muted
                  width="100%"
                  autoplay
                  loop
                  class="videos"
                  preload="auto"
                  webkit-playsinline="true"
                  playsinline="true"
                  x-webkit-airplay="true"
                  x5-playsinline="true"
                  x5-video-orientation="portraint"
                  poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
                >
                  <source
                    src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                    type="video/mp4"
                  />
                </video>

                <div class="con">Sold Out.</div>
              </div>
              <div class="soldout">
                <div class="opensea" @click="opensea">
                  view on
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
                  />
                </div>
                <div class="blur" @click="blur">
                  view on
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/blur.png"
                  />
                </div>
              </div>
            </div>
            <div class="yestart" v-show="status == 0 || status == -1">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/topbanner.png"
                class="nulltop"
              />
              <div :class="status == -1 ? 'margin yestartcon' : 'yestartcon'">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/small.png"
                  class="flower"
                />
                <video
                  muted
                  width="100%"
                  autoplay
                  loop
                  class="videos"
                  preload="auto"
                  webkit-playsinline="true"
                  playsinline="true"
                  x-webkit-airplay="true"
                  x5-playsinline="true"
                  x5-video-orientation="portraint"
                  poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
                >
                  <source
                    src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                    type="video/mp4"
                  />
                </video>
                <div class="con" v-if="status == 0">
                  Loony City admission not <br />started yet.
                </div>
                <div class="con" v-else-if="status == -1">Sold Out.</div>
              </div>
              <div class="soldout" v-show="status == -1">
                <div class="opensea" @click="opensea">
                  view on
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
                  />
                </div>
                <div class="blur" @click="blur">
                  view on
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/blur.png"
                  />
                </div>
              </div>
            </div>
            <div class="nullBox" v-show="indx === 0 && !arrlist">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/topbanner.png"
                class="nulltop"
              />
              <div class="nocard">You don't have any GENESIS</div>
            </div>
            <div class="tablist">
              <div class="tablistin" v-show="indx == 0" ref="tablistin">
                <div class="sel" @click.stop="selShowDown">
                  GENESIS <span>#{{ id }}</span>
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/arrow.png"
                    class="arrow"
                    v-if="selShow"
                  />
                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/arrowup.png"
                    class="arrow"
                    v-else
                  />
                </div>
                <div class="sellist" v-show="!selShow">
                  <div
                    :class="
                      arrlist && arrlist.length > 3
                        ? 'sellistin selover'
                        : 'sellistin'
                    "
                  >
                    <div
                      class="list"
                      v-for="(item, index) in arrlist"
                      :key="index"
                      @click.stop="sel(item)"
                    >
                      GENESIS <span>#{{ item.target_id }}</span>

                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/line.png"
                        class="line"
                        v-show="index != arrlist.length - 1"
                      />
                    </div>
                  </div>
                </div>
                <div class="expers" v-show="selShow">
                  <img :src="statusIcon1" v-if="statusIcon1" />
                  <span>{{ statusText1 }}</span>
                </div>
              </div>
              <div v-show="indx == 1" class="progress" ref="progress">
                <div class="circle">
                  <div class="ing"></div>
                </div>
                {{ processStatus }}

                <!-- <div class="progressin">
                <div class="circle">
                  <div class="exp" v-if="exp"></div>
                  <div v-else>
                    <div class="ready" v-if="ready"></div>
                    <div class="ing" v-else></div>
                  </div>
                </div>
                <div class="lineReady"></div>
                <div class="circle">
                  <div class="exp" v-if="exp2"></div>
                  <div v-else>
                    <div class="ready" v-if="ready2"></div>
                    <div class="ing" v-else></div>
                  </div>
                </div>
              </div> -->
              </div>
              <div class="tabCon">
                <div class="oimg" ref="oimg">
                  <video
                    muted
                    width="100%"
                    autoplay
                    loop
                    preload="auto"
                    webkit-playsinline="true"
                    playsinline="true"
                    x-webkit-airplay="true"
                    x5-playsinline="true"
                    x5-video-orientation="portraint"
                    poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
                  >
                    <source
                      src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="tabConList" v-show="indx == 0" id="tabConList1">
                  <div class="lists">
                    <div class="listcon">Remaining</div>
                    <div class="listnum">
                      {{ $store.state.order.orderInfo.stock }}/{{
                        original_stock
                      }}
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Price</div>
                    <div class="listnum">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                      />
                      0
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Quantity</div>
                    <div class="listnum">
                      <span>{{ genesis_claim }}</span>
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Total</div>
                    <div class="listnum total">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                      />
                      0
                    </div>
                  </div>
                </div>
                <div class="tabConList" v-show="indx == 1" id="tabConList2">
                  <div class="lists">
                    <div class="listcon">Remaining</div>
                    <div class="listnum">
                      {{ $store.state.order.orderInfo.stock }}/{{
                        original_stock
                      }}
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Price</div>
                    <div class="listnum">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                      />
                      {{ ethPrice }}
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Quantity</div>
                    <div class="listnum">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/jian.png"
                        class="jian"
                        @click="jian"
                        v-if="reduce"
                        :style="quantity == 0 ? 'display:none' : ''"
                      />
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/jian_g.png"
                        class="jian"
                        v-else
                        :style="quantity == 0 ? 'display:none' : ''"
                      />
                      <span>{{ quantity }}</span>
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/add.png"
                        class="add"
                        @click="add"
                        v-if="increase"
                        :style="quantity == 0 ? 'display:none' : ''"
                      />
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/add_g.png"
                        class="add"
                        v-else
                        :style="quantity == 0 ? 'display:none' : ''"
                      />
                    </div>
                  </div>
                  <div class="lists">
                    <div class="listcon">Total</div>
                    <div class="listnum total">
                      <img
                        src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                      />
                      {{ totalPrice }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mint" v-show="indx == 1" ref="mint">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/mint_g.png"
                  v-if="quantity == 0"
                  style="cursor: not-allowed"
                />
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/mint.gif"
                  v-else
                  @click="mint"
                />
              </div>

              <div class="mint" v-show="indx == 0" ref="mint1">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/claimbtn_g.png"
                  v-if="genesis_claim == 0"
                />
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/claimbtn.gif"
                  v-else
                  @click="claim"
                />
              </div>
            </div>
          </div>
          <div class="loading" v-else>Loading<span>...</span></div>
        </div>
        <div class="mintProcess" v-show="mintProcessing">
          <div class="in">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/close.png"
              class="closein"
              @click="closeMintProcess"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/footbg.png"
              class="footbg"
            />
            <div class="tip" v-if="!successful">Transaction Submitted.</div>
            <div class="tip" v-else>Welcome to the Loony City!</div>
            <!-- <img src="https://img.ohdat.io/loony_face/website/assets/1.jpg" class="oimg" v-if="!successful" /> -->
            <video
              muted
              width="100%"
              autoplay
              loop
              class="videos"
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              x5-playsinline="true"
              x5-video-orientation="portraint"
              poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
            >
              <source
                src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                type="video/mp4"
              />
            </video>
            <div class="bottom" v-if="!successful">
              <div class="circle">
                <div class="ing"></div>
              </div>
              Processing<span>...</span>
            </div>
            <div class="soldout" v-else>
              <div class="opensea" @click="opensea">
                view on
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
                />
              </div>
              <div class="blur" @click="blur">
                view on
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/blur.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="errorBox" v-show="error_now">
          <div class="in">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/close.png"
              class="closein"
              @click="closeError"
            />
            <div class="tip">ERROR</div>

            <div class="errortext">
              {{ error_text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_m overflow" ref="pageM">
      <div class="meauList" v-show="meauListShow">
        <!-- <div class="meaucon" ref="meaucon">navigation</div> -->
        <img
          src="https://img.ohdat.io/loony_face/website/assets/nav_logo.png"
          class="nav_log"
          @click.stop="navmouse"
          ref="navLog"
        />
        <div class="meauDown" ref="meauDown">
          <div class="mean" @click="goto(1)">home</div>
          <div class="mean" @click="goto(2)">artist</div>
          <div class="mean" @click="goto(3)">Project</div>
          <div class="mean" @click="goto(4)">loony city</div>
          <div class="mean" @click="goto(5)">Curator</div>
          <div class="mean means" @click="opensea">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/opensea.png"
            />
            Opensea
          </div>
          <div class="mean means" @click="twitter">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/twitter.png"
            />
            Twitter
          </div>
          <div class="mean means" @click="discard">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/discard.png"
            />
            Discord
          </div>
        </div>
      </div>
      <!-- home -->
      <div class="section1" id="PageM1">
        <div class="loading_m" v-show="loadingMShow" ref="secitionM">
          <div class="bg1" ref="bg1M"></div>
          <div class="bg2" ref="bg2M"></div>
          <div ref="rollMax" class="rollmax">
            <div class="roll" ref="loadingRoll">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/m/header.png"
                class="header"
                ref="logoTopM"
              />
              <img
                src="https://img.ohdat.io/loony_face/website/assets/m/smoke.png"
                class="smoke"
                ref="logoBtM"
              />
            </div>
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/conent.png"
              class="conent"
              ref="logoConM"
            />
            <!-- <div class="op" ref="opConM">Loading<span>...</span></div> -->
          </div>
        </div>
        <div class="logo" ref="logoM">
          <img
            src="https://img.ohdat.io/loony_face/website/assets/m/logo_m.png"
          />
        </div>

        <div class="banner" ref="bannerM">
          <img src="https://img.ohdat.io/loony_face/website/assets/m/m.gif" />
        </div>
        <div class="foot" ref="footM">
          <div class="foot_l">
            <!-- <div class="op">{{ walletAddress }}</div> -->
            <div class="con2">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/maxtext.png"
              />
            </div>
          </div>
          <div class="op" v-if="$store.state.order.walletAddress">
            {{ walletAddress }}
          </div>
          <div class="foot_r" @click="metaMaskConnect" v-if="!walletConnect">
            CONNECT WALLET
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/btn2.png"
              class="btn1"
            />
          </div>
          <div class="foot_r" @click="mtan" v-else>
            MINT
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/btn2.png"
              class="btn1"
            />
          </div>
          <img
            src="https://img.ohdat.io/loony_face/website/assets/m/btn1.png"
            class="btn2"
          />
        </div>
        <div class="white" ref="white"></div>
      </div>
      <!-- Artist -->
      <div class="section2" id="PageM2">
        <div class="top">
          Ouyang Ru, an urban artist that<br />mainly works on painting,
          sculpture,<br />
          and installation art. Most of his artworks are expressed by his<br />
          signature colors and the Loony Face<br />
          IP image, reflecting society and<br />
          humanity with strong satire and<br />humor. Expressing his
          attitudes<br />
          through arts, Ouyang maintains good<br />cooperation with many
          brands<br />and ventures.
        </div>
        <div class="footcon">
          <div class="conlist">
            <div class="title">6+</div>
            <div class="cons">Years</div>
          </div>
          <div class="conlist conlists">
            <div class="title">15+</div>
            <div class="cons">Brands Collabs</div>
          </div>
          <div class="conlist">
            <div class="title">25+</div>
            <div class="cons">Art Exhibitions</div>
          </div>
        </div>
        <div class="secbanner">
          <img
            src="https://img.ohdat.io/loony_face/website/assets/m/banner2.png"
          />
        </div>
      </div>
      <!-- Project -->
      <div class="section3" id="PageM3">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/mnew.png"
          class="mg"
        />
        <div class="top">
          Loony Face is an IP created by urban artist Ouyang Ru. Making
          impressions with its humorous, sarcastic image, Loony Face has made
          in-depth cooperation with 20+ renowned brands including Adidas,
          Starbucks, Universal Studios, and Absolut Vodka. The art piece
          reflects society and humanity and can be served as a portrait of a
          group of reckless "ordinary people" living in this era.
        </div>
        <div class="seclist">
          <div class="listtop">
            <div class="ll">
              <div class="tit">Sarcastic</div>
              <div class="cont">
                A playful interpretation of reality.The audience can freely
                interpret the metaphors behind the visual language, or just get
                sensory enjoyment from the silly yet insightful art style.
              </div>
            </div>
            <div class="ll">
              <div class="tit">Rebellious</div>
              <div class="cont">
                Anti-conventional, anti-order. In this world, there are no
                restrictions of laws and the so-called norms, because Loony
                Faces pursue freedom and truth behind all boundaries.
              </div>
            </div>
          </div>
          <div class="listmed">
            <div class="tit">symbolistic</div>
            <div class="cont contw">
              Loony Face has integrated the symbols of the times into artistic
              creation, scribbling down the absurdity and greatness of our era.
            </div>
          </div>
          <div class="listmed">
            <div class="tit">Dramatic</div>
            <div class="cont">
              By symbolizing the plight of the real world, the Loony City is a
              chaotic virtual city that is parallel to the real world, and what
              happens in reality is being staged in the form of a farce.
            </div>
          </div>
        </div>
      </div>
      <!-- lonnycity -->
      <div class="section4" id="PageM4">
        <img src="https://img.ohdat.io/loony_face/website/assets/citymbg.png" />
        <div class="pagecon">
          <div class="tit">loony city</div>
          <div class="tits">
            All holders of Loony Face are legal citizens of Loony City.
            Interspersing at the boundary of thevirtuality and reality, all
            citizens are both the audiences and the actors, creating a fictional
            utopia by themselves, for themselves.
          </div>
        </div>
      </div>
      <!-- About -->
      <div class="section5" id="PageM5">
        <div class="tit">CURATOR</div>
        <div class="top">
          Established in 2021, OHDAT Labs initiates and promotes the transfer to
          the Web3 world of renowned Web2 IPs (i.e. Peking Monsters, Producer
          C). Bridging the past and the future, the virtuality and the reality,
          OHDAT successfully secured $4 million in funding within 8 months.
        </div>
        <!-- <div class="listcon">
          <div class="list">
            <div class="ll">
              <div class="tit">Web3 Strategy</div>
              <div class="cont">
                Professional Web3 strategy based on the concept of story-verse,
                including art, roadmap, marketing, community, tokenomics, etc.
              </div>
            </div>
            <div class="ll">
              <div class="tit">Community Building</div>
              <div class="cont">
                Maintain a certain amount of community engagement on Twitter and
                Discord with the development of Discord bot.
              </div>
            </div>
          </div>
          <div class="footlist">
            <div class="tit">Technological Development</div>
            <div class="cont">
              Develop on-chain strategy and deploy safe and innovative smart
              contracts. Based on project theme, concept and lore, develop NFT
              websites with creative design.
            </div>
          </div>
        </div> -->
        <div class="footcont">
          <div class="footImg">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/footbg.png"
              class="foot"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/about_m.png"
              class="ban"
            />
          </div>

          <div class="last">
            <div class="last_l">
              <div class="tt" @click="ohdat" style="text-decoration: underline">
                https://www.ohdat.io
              </div>
              <div class="tt" @click="terms" style="text-decoration: underline">
                Terms and Conditions
              </div>
              <div class="tt">
                2023 Curated by OHDAT Labs. <br />All rights reserved.
              </div>
            </div>
            <div class="last_r" @click="ohdat">
              <img
                src="https://img.ohdat.io/loony_face/website/assets/ohdat.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="tan" style="display: none">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
          class="closeM"
          @click.stop="closeMbox"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/header_m.png"
          class="header_m"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/foot_m.png"
          class="foot_m"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/foot_flower.png"
          class="foot_flower"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/police_m.png"
          class="police_m"
        />
        <!-- <img src="https://img.ohdat.io/loony_face/website/assets/m/right_m.png" class="right_m" /> -->
        <div class="title">Fan Art Contest #1: <br />Stickers Invasion</div>
        <div class="overbox">
          <div class="tancon">
            The world is a boring place, but we will make it interestingby
            adding some little twists.
          </div>
          <div class="secList">
            <div class="titles">
              How to Enter:<img
                src="https://img.ohdat.io/loony_face/website/assets/m/flower_m.png"
                class="flower"
              />
            </div>
            <div class="conp">
              <p>
                1. Use the sticker sheets below and put the stickers anywhere
              </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;you want.</p>
              <p>
                2. Be as silly and creative as you want. Photoshop is allowed.
              </p>
              <p>3. Post you work on Twitter</p>
              <p>
                4. Tag <br />@LOONY_FACE_NF <br />
                and #loonyface
              </p>
            </div>
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/down_m.gif?tr=w-250,h-250"
              class="download"
              @click="downLoad"
            />
          </div>
        </div>
      </div>
      <div class="mintTan" v-show="mTanShow">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
          class="closeM"
          @click="closeMbox"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/t_m.png"
          class="t_m"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/b_m.png"
          class="b_m"
        />
        <div class="mintin">
          <div class="mintcen">
            <div class="mintbtn" @click="open(1)">MINT</div>
            <div class="mintbtn" @click="open(2)">GENESIS CLAIM</div>
            <div class="mintbtn" @click="open(3)" v-show="checkMshow">
              Check GENESIS
            </div>
            <div class="text" v-show="!checkMshow">
              Check if GENESIS has been used:
            </div>
            <div class="inptlist" v-show="!checkMshow">
              <div class="bt">
                <img :src="statusIcon" class="close" v-if="statusIcon" />
                {{ statusText }}
              </div>
              <input
                type="text"
                class="inpt"
                placeholder="ENTER GENESIS ID"
                v-model="val"
                @input="changeNumber"
              />
              <div class="enter" @click="enterCheck(val)">CHECK</div>
            </div>
          </div>
        </div>
      </div>
      <div class="openBox" v-show="openBox">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
          class="closeM"
          @click="closeOpen"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/foot_flower.png"
          class="foot_flower"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/poli.png"
          class="police_m"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/top.png"
          alt=""
          class="top"
        />

        <div v-if="showWhite">
          <div class="nullBox" v-show="mindx === 2 && !arrlist">
            <div class="nocard">You don't have any GENESIS</div>
          </div>
          <div class="title" v-if="mindx === 2">
            The Loony Face: GENESIS you are using is #{{ id }}
          </div>
          <div class="title title1" v-if="mindx === 1">
            Mint Loony Face: Main Collection
          </div>
          <div class="tablistin" v-if="mindx === 2">
            <div class="sel" @click.stop="selShowDown">
              GENESIS <span>#{{ id }}</span>
              <img
                src="https://img.ohdat.io/loony_face/website/assets/arrow.png"
                class="arrow"
                v-if="selShow"
              />
              <img
                src="https://img.ohdat.io/loony_face/website/assets/arrowup.png"
                class="arrow"
                v-else
              />
            </div>
            <div class="sellist" v-show="!selShow">
              <div
                :class="
                  arrlist && arrlist.length > 3
                    ? 'sellistin selover'
                    : 'sellistin'
                "
              >
                <div
                  class="list"
                  v-for="(item, index) in arrlist"
                  :key="index"
                  @click.stop="sel(item)"
                >
                  GENESIS <span>#{{ item.target_id }}</span>

                  <img
                    src="https://img.ohdat.io/loony_face/website/assets/line.png"
                    class="line"
                    v-show="index != arrlist.length - 1"
                  />
                </div>
              </div>
            </div>
            <div class="expers" v-show="selShow">
              <img :src="statusIcon1" v-if="statusIcon1" />
              <span>{{ statusText1 }}</span>
            </div>
          </div>
          <div v-else class="progress">
            <div class="circle">
              <div class="ing"></div>
            </div>
            {{ processStatus }}
          </div>
          <div class="oimg">
            <video
              muted
              width="100%"
              autoplay
              loop
              class="videos"
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              x5-playsinline="true"
              x5-video-orientation="portraint"
              poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
            >
              <source
                src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <!-- mint -->
          <div class="tabConList" v-if="mindx == 1">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/flower_m.png"
              class="flower_m"
            />
            <div class="lists">
              <div class="listcon">Remaining</div>
              <div class="listnum">
                {{ $store.state.order.orderInfo.stock }}/{{ original_stock }}
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Price</div>
              <div class="listnum">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                />
                {{ ethPrice }}
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Quantity</div>
              <div class="listnum">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/jian.png"
                  class="jian"
                  @click="jian"
                  v-if="reduce"
                  :style="quantity == 0 ? 'display:none' : ''"
                />
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/jian_g.png"
                  class="jian"
                  v-else
                  :style="quantity == 0 ? 'display:none' : ''"
                />
                <span>{{ quantity }}</span>
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/add.png"
                  class="add"
                  @click="add"
                  v-if="increase"
                  :style="quantity == 0 ? 'display:none' : ''"
                />
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/add_g.png"
                  class="add"
                  v-else
                  :style="quantity == 0 ? 'display:none' : ''"
                />
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Total</div>
              <div class="listnum total">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                />
                {{ totalPrice }}
              </div>
            </div>
          </div>
          <!-- claim -->
          <div class="tabConList" v-if="mindx == 2">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/flower_m.png"
              class="flower_m"
            />
            <div class="lists">
              <div class="listcon">Remaining</div>
              <div class="listnum">
                {{ $store.state.order.orderInfo.stock }}/{{ original_stock }}
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Price</div>
              <div class="listnum">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                />
                0
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Quantity</div>
              <div class="listnum">
                <span>{{ genesis_claim }}</span>
              </div>
            </div>
            <div class="lists">
              <div class="listcon">Total</div>
              <div class="listnum total">
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/eth.png"
                />
                0
              </div>
            </div>
          </div>

          <div class="mint" v-if="mindx === 1">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/mint_g.png"
              v-if="quantity == 0"
              style="cursor: not-allowed"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/mint.gif"
              v-else
              @click="mint"
            />
          </div>

          <div class="mint" v-else>
            <img
              src="https://img.ohdat.io/loony_face/website/assets/claimbtn_g.png"
              v-if="genesis_claim == 0"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/claimbtn.gif"
              v-else
              @click="claim"
            />
          </div>
        </div>
        <div class="loading" v-else>Loading<span>...</span></div>
        <div class="mintProcess" v-show="mintProcessing">
          <div class="in">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
              class="closein"
              @click="closeMintProcess"
            />
            <img
              src="https://img.ohdat.io/loony_face/website/assets/footbg.png"
              class="footbg"
            />
            <div class="tip" v-if="!successful">Transaction Submitted.</div>
            <div class="tip" v-else>Welcome to the Loony City!</div>
            <!-- <img src="https://img.ohdat.io/loony_face/website/assets/1.jpg" class="oimg" v-if="!successful" /> -->
            <video
              muted
              width="100%"
              autoplay
              loop
              class="videos"
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              x5-playsinline="true"
              x5-video-orientation="portraint"
              poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
            >
              <source
                src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
                type="video/mp4"
              />
            </video>
            <div class="bottom" v-if="!successful">
              <div class="circle">
                <div class="ing"></div>
              </div>
              Processing<span>...</span>
            </div>
            <div class="soldout" v-else>
              <div class="opensea" @click="opensea">
                view on
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
                />
              </div>
              <div class="blur" @click="blur">
                view on
                <img
                  src="https://img.ohdat.io/loony_face/website/assets/blur.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="errorBox" v-show="error_now">
          <div class="in">
            <img
              src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
              class="closein"
              @click="closeError"
            />
            <div class="tip">ERROR</div>

            <div class="errortext">
              {{ error_text }}
            </div>
          </div>
        </div>
      </div>
      <div class="nostart" v-show="nostartM">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/start1.png"
          class="start1"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/start3.png"
          class="start3"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
          class="closeM"
          @click="closeNostart"
        />
        <div class="yestartcon">
          <video
            muted
            width="100%"
            autoplay
            loop
            class="oimg"
            preload="auto"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="true"
            x5-playsinline="true"
            x5-video-orientation="portraint"
            poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
          >
            <source
              src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
              type="video/mp4"
            />
          </video>

          <div class="con" v-if="status == 0">
            Loony City admission not <br />started yet.
          </div>
          <div class="con" v-else-if="status == -1">Sold Out.</div>
          <div class="soldout" v-show="status == -1">
            <div class="opensea" @click="opensea">
              view on
              <img
                src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
              />
            </div>
            <div class="blur" @click="blur">
              view on
              <img
                src="https://img.ohdat.io/loony_face/website/assets/blur.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="nostart" v-show="mSold">
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/start1.png"
          class="start1"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/start3.png"
          class="start3"
        />
        <img
          src="https://img.ohdat.io/loony_face/website/assets/m/closeM.png"
          class="closeM"
          @click="closeNostart1"
        />
        <div class="yestartcon">
          <video
            muted
            width="100%"
            autoplay
            loop
            class="oimg"
            preload="auto"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="true"
            x5-playsinline="true"
            x5-video-orientation="portraint"
            poster="https://img.ohdat.io/loony_face/website/assets/videobg.jpg"
          >
            <source
              src="https://img.ohdat.io/loony_face/website/assets/1.mp4"
              type="video/mp4"
            />
          </video>

          <div class="con">Sold Out.</div>
          <div class="soldout">
            <div class="opensea" @click="opensea">
              view on
              <img
                src="https://img.ohdat.io/loony_face/website/assets/opensa.png"
              />
            </div>
            <div class="blur" @click="blur">
              view on
              <img
                src="https://img.ohdat.io/loony_face/website/assets/blur.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout, initialWeb3, getCurrentWallet } from "@/utils/web3.js";
import Web3 from "web3";
import { ethers, Wallet } from "ethers";
import { get_goods_datail, get_purchase_options } from "@/api/goods/index.js";
import { list_all_my_loony_face_genesis } from "@/api/item/index.js";
import {
  place_goods_order_submit,
  place_goods_order,
} from "@/api/trade/index.js";
import { Message } from "element-ui";
import { LoonyFace__factory } from "@ohdat/nft";
export default {
  name: "LoonyFace",
  data() {
    return {
      claimPirce: "",
      mintProcessing: false,
      successful: true,
      actives: 0,
      gggg: 0,
      i: "",
      overlist: true,
      boxShow: false,
      mintShow: false,
      preloadCount: 0,
      percent: "",
      mTanShow: false,
      sliderdown: false,
      meauListShow: true,
      hover1: false,
      hover2: false,
      hover3: false,
      navShow: true,
      num: 0,
      rolling: true,
      loadingShow: true,
      loadingMShow: true,
      flag: true,
      tabText: ["GENESIS Claim", "MINT"],
      indx: -1,
      mindx: -1,
      val: "",
      arrlist: "",
      selShow: true,
      id: "",
      quantity: "",
      increase: false,
      reduce: false,
      exp: false,
      ready: false,
      exp2: false,
      ready2: true,
      // ethprice:Web3.utils.fromWei(res.data.price + "", "ether"),
      openBox: false,
      walletConnect: false,
      currentAccounttext: "",
      status: "",
      stock: "",
      original_stock: "",
      ethPrice: "",
      contract: "",
      limit: "",
      totalPrice: "",
      token_address: "",
      contractList: "",
      limtNum: "",
      error_text: "",
      error_now: false,
      mint_limit: "",
      genesis_claim_limit: "",
      statusIcon: "",
      statusText: "",
      statusIcon1: "",
      statusText1: "",
      genesis_claim: "",
      processStatus: "",
      checkMshow: true,
      nostartM: false,
      showWhite: false,
      rarity: "",
      mSold: false,
    };
  },

  mounted() {
    this.preload();
    this.getGoodsDetail();
    if (window.screen.width > 1024) {
      window.addEventListener("scroll", this.handleScroll, true);
    } else {
      var len = this.$refs.secitionM.clientHeight;
      var lenImg = this.$refs.rollMax.clientHeight;
      var leng = Number(len / 2 - lenImg / 1.7);
      this.$refs.loadingRoll.style.marginTop = leng + "px";
    }

    this.loading();
  },
  computed: {
    walletAddress: function () {
      return (
        "HEY," +
        this.$store.state.order.walletAddress.substr(0, 4) +
        "***" +
        this.$store.state.order.walletAddress.substr(-4)
      );
    },
  },
  watch: {
    "$store.state.order.walletAddress": function (oldValue, newValue) {
      console.log("oldval=====" + oldValue);
      console.log("newval=====" + newValue);

      let that = this;
      if (oldValue == "") {
        that.walletConnect = false;
      } else {
        that.walletConnect = true;
        that.arrlist = "";
        that.indx = -1;
        that.id = "";
        that.showWhite = false;
        that.getGoodsDetail();
        if (that.mintShow) {
          that.getPurchaseOptions();
        }
      }
    },
  },
  methods: {
    changeNumber(event) {
      if (this.val == "") {
        this.statusText = "";
        this.statusIcon = "";
      }
      if (this.val.length > 2) {
        this.val = this.val.replace(/^(0+)|[^\d]+/g, "");
      } else {
        this.val = this.val.replace(/[^\d]+/g, "");
      }

      if (this.val.length > 2) {
        this.val = this.val.slice(0, 2);
      }
    },
    async enterCheck(val, params) {
      let that = this;
      if (val < 0 || val > 999) {
        //错
        if (params && params == "checkList") {
          that.statusIcon1 =
            "https://img.ohdat.io/loony_face/website/assets/close2.png";
          that.statusText1 = "Invalid genesis ID";
        } else {
          that.statusIcon =
            "https://img.ohdat.io/loony_face/website/assets/close2.png";
          that.statusText = "Invalid genesis ID";
        }
      } else {
        let checkGenesisClaimQuantity =
          await that.contractList.checkGenesisClaimQuantity(val);
        console.log(checkGenesisClaimQuantity.toString());
        var num = Number(
          that.genesis_claim_limit - checkGenesisClaimQuantity.toString()
        );
        that.genesis_claim = num;
        that.claimPirce = Number(that.genesis_claim * that.ethPrice);
        console.log("111111" + that.claimPirce);
        console.log("this.genesis_claim===" + that.genesis_claim);

        if (num > 0) {
          //对
          if (params && params == "checkList") {
            that.statusIcon1 =
              "https://img.ohdat.io/loony_face/website/assets/alright.png";
            that.statusText1 =
              "This GENESIS is eligible for 1 Loony Face: Main Collection NFT.";
          } else {
            that.statusIcon =
              "https://img.ohdat.io/loony_face/website/assets/alright.png";
            that.statusText =
              "This GENESIS is eligible for 1 Loony Face: Main Collection NFT.";
          }
        } else {
          //错
          if (params && params == "checkList") {
            that.statusIcon1 =
              "https://img.ohdat.io/loony_face/website/assets/close2.png";
            that.statusText1 = "This GENESIS has been used.";
          } else {
            that.statusIcon =
              "https://img.ohdat.io/loony_face/website/assets/close2.png";
            that.statusText = "This GENESIS has been used.";
          }
        }
      }
    },
    closeError() {
      this.error_now = false;
    },
    closeMintProcess() {
      this.mintProcessing = false;
    },
    //pass卡
    async cardlist(i) {
      let data = await list_all_my_loony_face_genesis();
      this.arrlist = data.data.item_list;
      if (i == "m") {
        this.id = this.arrlist[0].target_id;
        this.enterCheck(this.id, "checkList");
      } else {
        if (!this.arrlist) {
          this.indx = 1;
        } else {
          if (this.indx == -1) {
            this.indx = 0;
          }
          this.id = this.arrlist[0].target_id;
          this.enterCheck(this.id, "checkList");
          // alert(this.id)
        }
      }
    },
    async checkNet() {
      let provider = window.web3Provider;
      let network = await provider.getNetwork(process.env.VUE_APP_MAIN_NETWORK);
      console.log(11111, network);
      if (network.chainId != process.env.VUE_APP_MAIN_NETWORK) {
        try {
          Message({
            message: "Please switch to Ethereum Main Network",
            type: "error",
            duration: 3 * 1000,
          });
          await provider.send("wallet_switchEthereumChain", [
            {
              chainId: Web3.utils.numberToHex(process.env.VUE_APP_MAIN_NETWORK),
            },
          ]);
          return true;
        } catch (error) {
          console.log("switch network", error);
          return false;
        }
      } else {
        return true;
      }
    },
    async claim() {
      let netResult = await this.checkNet();
      if (!netResult) {
        return;
      }
      let that = this;
      that.mintProcessing = true;
      that.successful = false;
      let transactionSubmit;
      console.log(that.genesis_claim, that.id);
      //是否点击确认
      try {
        transactionSubmit = await that.contractList.genesisClaim(
          that.genesis_claim,
          that.id
        );
      } catch (error) {
        console.log("transaction submit failed", error);
        if (error.toString().includes("insufficient funds")) {
          //没钱
          // alert("我没钱")
          that.error_text = "Insufficient funds";
          that.error_now = true;
          that.mintProcessing = false;
        } else if (error.toString().includes("user rejected transaction")) {
          //用户拒绝
          // that.error_text = "user rejected transaction";
          // that.error_now = true;
          that.mintProcessing = false;
        } else if (error.toString().includes("transaction may fail")) {
          that.error_text = "Transaction might fail";
          that.error_now = true;
          that.mintProcessing = false;
        } else if (error.toString().includes("mint not available")) {
          that.error_text = "Mint not started yet";
          that.error_now = true;
          that.mintProcessing = false;
        } else {
          Message({
            message: error.toString(),
            type: "error",
            duration: 3 * 1000,
          });
          that.error_text = "Transaction submit failed";
          that.error_now = true;
          that.mintProcessing = false;
        }

        return;
      }

      try {
        let transactionComplete = await transactionSubmit.wait();
        console.log(transactionComplete, "receipt");
        that.getGoodsDetail();
        that.getPurchaseOptions();
        that.successful = true;
      } catch (e) {
        console.log("transaction complete failed", e);
        Message({
          message: error.toString(),
          type: "error",
          duration: 3 * 1000,
        });
        that.error_text = "Transaction failed";
        that.error_now = true;
        that.mintProcessing = false;
      }
    },
    async mint() {
      let netResult = await this.checkNet();
      if (!netResult) {
        return;
      }
      let that = this;
      that.mintProcessing = true;
      that.successful = false;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.quantity);
      formData.append("goods_id", "13");
      formData.append("quantity", this.quantity);
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        let orderInfo = res.data;
        let transactionSubmit;
        console.log(that.contractList);
        console.log(this.contract);
        //是否点击确认
        try {
          if (this.contract == "mint") {
            transactionSubmit = await that.contractList.mint(
              orderInfo.quantity,
              {
                value: orderInfo.price.toString(),
              }
            );
          } else if (this.contract == "white_list_mint") {
            transactionSubmit = await that.contractList.whiteListMint(
              orderInfo.quantity,
              orderInfo.hash,
              orderInfo.signature,
              orderInfo.block_height,
              orderInfo.nonce,
              { value: orderInfo.price.toString() }
            );
          } else {
            return;
          }
          console.log("confirm transaction");
          that.placeGoodsOrderSubmit(orderInfo.nonce);
        } catch (error) {
          console.log("transaction submit failed", error);
          if (error.toString().includes("insufficient funds")) {
            //没钱
            // alert("我没钱")
            that.error_text = "Insufficient funds";
            that.error_now = true;
            that.mintProcessing = false;
          } else if (error.toString().includes("user rejected transaction")) {
            //用户拒绝
            // that.error_text = "user rejected transaction";
            // that.error_now = true;
            that.mintProcessing = false;
          } else if (error.toString().includes("transaction may fail")) {
            that.error_text = "Transaction might fail";
            that.error_now = true;
            that.mintProcessing = false;
          } else if (error.toString().includes("mint not available")) {
            that.error_text = "Mint not started yet";
            that.error_now = true;
            that.mintProcessing = false;
          } else {
            Message({
              message: error.toString(),
              type: "error",
              duration: 3 * 1000,
            });
            that.error_text = "Transaction submit failed";
            that.error_now = true;
            that.mintProcessing = false;
          }

          return;
        }

        try {
          let transactionComplete = await transactionSubmit.wait();
          console.log(transactionComplete, "receipt");
          that.getGoodsDetail();
          that.getPurchaseOptions();
          that.successful = true;
        } catch (e) {
          console.log("transaction complete failed", e);
          Message({
            message: error.toString(),
            type: "error",
            duration: 3 * 1000,
          });
          that.error_text = "Transaction failed";
          that.error_now = true;
          this.mintProcessing = false;
        }
      } else {
        console.log("place_goods_order", "err");
      }
    },
    //status获取
    async getGoodsDetail() {
      const params = {
        id: 13,
      };
      let res = await get_goods_datail(params);
      console.log(res);
      if (res.code == 200) {
        this.stock = res.data.stock;
        this.original_stock = res.data.original_stock;
        this.status = res.data.status;
        this.token_address = res.data.token_address;
        this.mint_limit = res.data.limit_info.mint_limit;
        this.genesis_claim_limit = res.data.limit_info.genesis_claim_limit;
        this.rarity = res.data.rarity;
        // this.contractAddress = res.data.token_address;
        this.$store.commit("order/POST_GOODS_INFO", res.data);
        if (res.data.status == 0) {
        } else if (res.data.status == -1) {
          // sould out
        } else if (res.data.status == 1) {
          //公售
        } else if (res.data.status == 4) {
          //白名单
        }
      } else {
        console.log("res err", res);
      }
    },
    //mint 状态
    async getPurchaseOptions() {
      const params = {
        id: 13,
      };
      let res = await get_purchase_options(params);
      console.log(res);
      if (res.code === 200) {
        this.ethPrice = Web3.utils.fromWei(res.data.price + "", "ether");
        this.contract = res.data.contract_method;

        if (res.data.contract_method == "mint") {
          this.processStatus = "PUBLIC MINT";
        } else {
          this.processStatus = "WHITELIST MINT";
        }

        let provider = window.web3Provider;

        const signer = provider.getSigner();
        this.contractList = await LoonyFace__factory.connect(
          this.token_address,
          signer
        );
        let checkMintQuantity = await this.contractList.checkMintQuantity(
          this.$store.state.order.walletAddress
        );
        this.quantity = Number(this.mint_limit - checkMintQuantity.toString());
        this.limtNum = Number(this.mint_limit - checkMintQuantity.toString());
        console.log("this.quantity===" + this.quantity);
        this.increase = false;
        if (this.quantity == 0) {
          this.totalPrice = 0;
        } else {
          if (this.quantity == 1) {
            this.reduce = false;
          }
          this.reduce = true;
          this.totalPrice = (this.ethPrice * this.quantity).toFixed(3);
        }
        await this.cardlist();
        this.showWhite = true;
        console.log("this.quantity====" + this.quantity);
      } else if (
        res.code === 1101 ||
        res.code === 1102 ||
        res.code === 1103 ||
        res.code === 1104 ||
        res.code === 1105
      ) {
        // this.limit = 0;
        // this.available = 0;
        // this.limitall = 0;
        // this.totalPrice = 0;
      }
    },
    async placeGoodsOrderSubmit(nonce) {
      let formData = new FormData();
      formData.append("nonce", nonce);
      let res = await place_goods_order_submit(formData);
      console.log("placeGoodsOrderSubmit", res);
      if (res.code == 200) {
        console.log("place_goods_order_submit", "true");
        // this.$store.commit('mask/changeIndex', 1)
      } else {
        console.log("place_goods_order_submit", "err");
      }
    },
    jian() {
      this.quantity--;
      if (this.quantity <= 1) {
        this.quantity = 1;
        this.reduce = false;
      }
      if (this.limtNum > 1) {
        this.increase = true;
      }
      this.totalPrice = (this.ethPrice * this.quantity).toFixed(3);
    },
    add() {
      this.quantity++;
      this.reduce = true;
      if (this.quantity >= this.limtNum) {
        this.increase = false;
      }
      this.totalPrice = (this.ethPrice * this.quantity).toFixed(3);
    },
    docSel() {
      this.selShow = true;
    },
    selShowDown() {
      this.selShow = !this.selShow;
    },
    sel(item) {
      this.selShow = !this.selShow;
      this.id = item.target_id;
      this.enterCheck(this.id, "checkList");
    },
    closeOpen() {
      this.openBox = false;
      this.$refs.pageM.classList.remove("overflow");
    },
    open(i) {
      if (i < 3) {
        this.mTanShow = false;
        this.openBox = true;
      }
      if (i == 1) {
        //mint
        // this.mindx = 1;
        if (this.rarity == 1) {
          this.mSold = true;
        } else {
          this.mSold = false;
          this.mindx = 1;
        }
      } else if (i == 2) {
        //claim
        this.mindx = 2;
      } else {
        this.checkMshow = false;
      }
    },
    tabChange(i) {
      this.$refs.mint.classList.add("loadingopa");
      this.$refs.mint1.classList.add("loadingopa");
      var tabConList1 = document.getElementById("tabConList1");
      var tabConList2 = document.getElementById("tabConList2");
      tabConList1.classList.add("loadingopa");
      tabConList2.classList.remove("loadingopa");
      this.$refs.progress.classList.add("loadingopa");
      this.$refs.tablistin.classList.add("loadingopa");
      this.$refs.title1.classList.add("loadingopa");
      this.$refs.title2.classList.add("loadingopa");
      this.$refs.oimg.classList.add("loadingopa");

      setTimeout(() => {
        tabConList1.classList.remove("loadingopa");
        tabConList2.classList.remove("loadingopa");
        this.$refs.oimg.classList.remove("loadingopa");
        this.$refs.mint.classList.remove("loadingopa");
        this.$refs.mint1.classList.remove("loadingopa");
        this.$refs.progress.classList.remove("loadingopa");
        this.$refs.tablistin.classList.remove("loadingopa");
        this.$refs.title1.classList.remove("loadingopa");
        this.$refs.title2.classList.remove("loadingopa");
      }, 600);
      if (this.indx == -1) {
        return;
      }
      this.val = "";
      this.statusText = "";
      this.statusIcon = "";
      this.indx = i;
      if (i == 1) {
        this.quantity = this.limtNum;
        this.increase = false;
        if (this.quantity == 0) {
          this.totalPrice = 0;
        } else {
          this.totalPrice = (this.ethPrice * this.quantity).toFixed(3);
        }

        if (this.quantity > 1) {
          this.reduce = true;
        } else {
          this.reduce = false;
        }
      }
    },
    async metaMaskConnect() {
      await initialWeb3("injected");
      await getCurrentWallet();
    },
    discard() {
      window.open("https://discord.gg/ohdatlabs");
    },
    twitter() {
      window.open("https://twitter.com/LOONY_FACE_NFT");
    },
    downLoad() {
      window.location.href =
        "https://master.ohdat.io/loony_face/common/stickers.zip";
    },
    mousehovers(i) {
      if (i == 1) {
        this.hover1 = true;
      } else if (i == 2) {
        this.hover2 = true;
      } else if (i == 3) {
        this.hover3 = true;
      }
    },
    mouseleaves(i) {
      if (i == 1) {
        this.hover1 = false;
      } else if (i == 2) {
        this.hover2 = false;
      } else if (i == 3) {
        this.hover3 = false;
      }
    },

    goto(i) {
      this.flag = true;
      this.navleave();
      document.querySelector("#PageM" + i + "").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    navleave() {
      this.$refs.navLog.classList.add("transUp");
      this.$refs.meauDown.classList.add("upslide");
      // this.$refs.meaucon.classList.add("conOp1");
      setTimeout(() => {
        this.$refs.navLog.classList.remove("trans");
        this.$refs.meauDown.classList.remove("downslide");
        this.$refs.navLog.classList.remove("transUp");
        this.$refs.meauDown.classList.remove("upslide");
        // this.$refs.meaucon.classList.remove("conOp1");
        // this.$refs.meaucon.classList.remove("conOp");
      }, 700);
    },
    navmouse() {
      if (this.flag) {
        this.flag = false;
        this.$refs.navLog.classList.add("trans");
        this.$refs.meauDown.classList.add("downslide");
      } else {
        this.flag = true;
        this.navleave();
      }

      // this.$refs.meaucon.classList.add("conOp");
    },
    closeMbox() {
      this.mTanShow = false;
      this.$refs.pageM.classList.remove("overflow");
    },
    closeNostart() {
      this.nostartM = false;
    },
    closeNostart1() {
      this.mSold = false;
      this.mTanShow = false;
      this.openBox = false;
    },
    async mtan() {
      let netResult = await this.checkNet();
      if (!netResult) {
        return;
      }
      this.mintShow = true;
      this.getGoodsDetail();
      this.getPurchaseOptions();
      console.log(this.status);
      this.$refs.pageM.classList.add("overflow");
      if (this.status == 0 || this.status == -1) {
        this.nostartM = true;
      } else {
        this.mTanShow = true;
        this.cardlist("m");
      }
    },
    ohdat() {
      window.open("https://www.ohdat.io/");
    },
    terms() {
      window.open("https://master.ohdat.io/loony_face/common/terms.pdf");
    },
    close() {
      this.mintProcessing = false;
      this.$refs.boxin.classList.add("hiden");
      this.$refs.yellow.classList.add("hidenl");
      this.$refs.leftFoot.classList.add("opa");
      setTimeout(() => {
        // this.boxShow = false;文案介绍弹窗
        this.mintShow = false; //mint 弹窗
        this.$refs.boxin.classList.remove("hiden");
        this.$refs.yellow.classList.remove("hidenl");
        this.$refs.leftFoot.classList.remove("opa");
      }, 600);
    },
    async checkbox() {
      // await this.checkNet()
      let netResult = await this.checkNet();
      if (!netResult) {
        return;
      }
      this.mintShow = true;
      this.getGoodsDetail();
      this.getPurchaseOptions();
    },
    navLogo() {
      if (this.navShow) {
        this.$refs.meauList.classList.add("slideright");
        this.$refs.nav.classList.add("rotatnormal");
        this.navShow = false;
      } else {
        this.navShow = true;
        this.$refs.nav.classList.add("rotat");
        this.$refs.meauList.classList.add("slideleft");
        setTimeout(() => {
          this.$refs.nav.classList.remove("rotat");
          this.$refs.meauList.classList.remove("slideleft");
          this.$refs.nav.classList.remove("rotatnormal");
          this.$refs.meauList.classList.remove("slideright");
        }, 600);
      }
    },
    goPage(i) {
      console.log(document.querySelector("#page" + i + ""));
      document.querySelector("#page" + i + "").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    handleScroll(e) {
      var sec = document.getElementsByClassName("section")[0].clientHeight * 4;
      var wid = this.$refs.scroll.clientWidth;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        e.target.scrollTop;
      var kkk = (
        (wid / document.getElementsByClassName("section")[0].clientHeight) *
        100
      ).toFixed(2);
      if (((scrollTop / sec) * 100).toFixed(2) - kkk <= 0) {
        this.$refs.scroll.style.left = 0;
      } else {
        this.$refs.scroll.style.left =
          ((scrollTop / sec) * 100).toFixed(2) - kkk + "%";
      }
      var clipNum = Number(((scrollTop / sec) * 100).toFixed(2));
      var scroll = clipNum - this.i;
      // console.log(clipNum);
      // console.log(scroll);
      this.i = clipNum;
      //   console.log(this.gggg);
      if (clipNum < 8) {
        this.gggg = 0;
      } else if (clipNum >= 8 && clipNum <= 25) {
        if (scroll > 0) {
          if (this.gggg >= 100) {
            this.gggg = 100;
          } else {
            this.gggg = this.gggg + 5 * Math.abs(scroll);
          }
        } else {
          if (this.gggg <= 0) {
            this.gggg = 0;
          } else {
            this.gggg = this.gggg - 5 * Math.abs(scroll);
          }
        }
      } else if (clipNum > 25) {
        this.gggg = 100;
      }
      document.getElementById(
        "mask"
      ).style.clip = `rect(${this.gggg}vw, auto, auto, 0)`;
    },
    loading() {
      let timer = setInterval(() => {
        this.num++;
        if (this.num >= 360) {
          this.num = 0;
          if (!this.rolling) {
            clearInterval(timer);
            setTimeout(() => {
              this.$refs.bg1.classList.add("rollLoad");
              this.$refs.bg2.classList.add("rollLoadDown");
              this.$refs.logoTop.classList.add("rollLoadLogo");
              this.$refs.logoBt.classList.add("rollLoadDownLogo");
              this.$refs.logoCon.classList.add("rollLoadDownLogo");
              // this.$refs.opCon.classList.add("rollLoadDownLogo");
              //移动端
              this.$refs.bg1M.classList.add("rollLoad");
              this.$refs.bg2M.classList.add("rollLoadDown");
              this.$refs.logoTopM.classList.add("rollLoadLogo");
              this.$refs.logoBtM.classList.add("rollLoadDownLogo");
              this.$refs.logoConM.classList.add("rollLoadDownLogo");
              setTimeout(() => {
                this.loadingShow = false;
                this.loadingMShow = false;
                this.$refs.content.classList.add("overflow");
                this.$refs.scroll.style.display = "block";
                this.$refs.pageM.classList.remove("overflow");
              }, 600);
            }, 900);
          }
        } else {
          //pc
          this.$refs.rotateLoad.style.transform = `rotate(${this.num}deg)`;
          //移动
          this.$refs.loadingRoll.style.transform = `rotate(${this.num}deg)`;
        }
        // console.log(this.num);
      }, 5);
    },
    preload() {
      // alert()
      var loading;
      // if (window.screen.width < 1024) {
      //   loading = document.getElementById("loading_m");
      // } else {
      //   loading = document.getElementById("loading");
      // }
      let imgs = [
        "https://img.ohdat.io/loony_face/website/assets/logo_top.png",
        "https://img.ohdat.io/loony_face/website/assets/logo_bt.png",
        "https://img.ohdat.io/loony_face/website/assets/logo_con.png",
        "https://img.ohdat.io/loony_face/website/assets/logo.png",
        "https://img.ohdat.io/loony_face/website/assets/feiren.gif",
        "https://img.ohdat.io/loony_face/website/assets/lefticon.png",
        "https://img.ohdat.io/loony_face/website/assets/icon_page1.png",
        "https://img.ohdat.io/loony_face/website/assets/page1_btn.png",
        "https://img.ohdat.io/loony_face/website/assets/opensea.png",
        "https://img.ohdat.io/loony_face/website/assets/twitter.png",
        "https://img.ohdat.io/loony_face/website/assets/discard.png",
        "https://img.ohdat.io/loony_face/website/assets/nav_logo.png",
        "https://img.ohdat.io/loony_face/website/assets/hover1.png",
        "https://img.ohdat.io/loony_face/website/assets/hover2.png",
        "https://img.ohdat.io/loony_face/website/assets/hover3.png",
        "https://img.ohdat.io/loony_face/website/assets/page2banner.png",
        "https://img.ohdat.io/loony_face/website/assets/bg.png",
        "https://img.ohdat.io/loony_face/website/assets/aboutbanner.png",
        "https://img.ohdat.io/loony_face/website/assets/ohdat.png",
        "https://img.ohdat.io/loony_face/website/assets/close.png",
        "https://img.ohdat.io/loony_face/website/assets/tanbox1.png",
        "https://img.ohdat.io/loony_face/website/assets/tanbox2.png",
        "https://img.ohdat.io/loony_face/website/assets/police.png",
        "https://img.ohdat.io/loony_face/website/assets/slice.png",
        "https://img.ohdat.io/loony_face/website/assets/pc.gif",
        "https://img.ohdat.io/loony_face/website/assets/small.png",
        "https://img.ohdat.io/loony_face/website/assets/topbanner2.png",
        "https://img.ohdat.io/loony_face/website/assets/topbanner.png",
        "https://img.ohdat.io/loony_face/website/assets/opensa.png",
        "https://img.ohdat.io/loony_face/website/assets/blur.png",
        "https://img.ohdat.io/loony_face/website/assets/arrow.png",
        "https://img.ohdat.io/loony_face/website/assets/arrowup.png",
        "https://img.ohdat.io/loony_face/website/assets/line.png",
        "https://img.ohdat.io/loony_face/website/assets/eth.png",
        "https://img.ohdat.io/loony_face/website/assets/jian.png",
        "https://img.ohdat.io/loony_face/website/assets/jian_g.png",
        "https://img.ohdat.io/loony_face/website/assets/add.png",
        "https://img.ohdat.io/loony_face/website/assets/add_g.png",
        "https://img.ohdat.io/loony_face/website/assets/mint_g.png",
        "https://img.ohdat.io/loony_face/website/assets/mint.gif",
        "https://img.ohdat.io/loony_face/website/assets/claimbtn_g.png",
        "https://img.ohdat.io/loony_face/website/assets/claimbtn.gif",
        "https://img.ohdat.io/loony_face/website/assets/footbg.png",
        "https://img.ohdat.io/loony_face/website/assets/1.jpg",
        "https://img.ohdat.io/loony_face/website/assets/m/header.png",
        "https://img.ohdat.io/loony_face/website/assets/m/smoke.png",
        "https://img.ohdat.io/loony_face/website/assets/m/conent.png",
        "https://img.ohdat.io/loony_face/website/assets/m/logo_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/m.gif",
        "https://img.ohdat.io/loony_face/website/assets/m/btn2.png",
        "https://img.ohdat.io/loony_face/website/assets/m/btn1.png",
        "https://img.ohdat.io/loony_face/website/assets/m/banner2.png",
        "https://img.ohdat.io/loony_face/website/assets/m/mnew.png",
        "https://img.ohdat.io/loony_face/website/assets/citymbg.png",
        "https://img.ohdat.io/loony_face/website/assets/m/footbg.png",
        "https://img.ohdat.io/loony_face/website/assets/m/about_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/closeM.png",
        "https://img.ohdat.io/loony_face/website/assets/m/header_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/foot_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/foot_flower.png",
        "https://img.ohdat.io/loony_face/website/assets/m/police_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/right_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/flower_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/down_m.gif",
        "https://img.ohdat.io/loony_face/website/assets/m/t_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/b_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/poli.png",
        "https://img.ohdat.io/loony_face/website/assets/m/start1.png",
        "https://img.ohdat.io/loony_face/website/assets/m/start3.png",
        "https://img.ohdat.io/loony_face/website/assets/down.gif",
        "https://img.ohdat.io/loony_face/website/assets/m/mg.png",
        "https://img.ohdat.io/loony_face/website/assets/m/citym.png",
        "https://img.ohdat.io/loony_face/website/assets/banner.png",
        "https://img.ohdat.io/loony_face/website/assets/boxbanner.png",
        "https://img.ohdat.io/loony_face/website/assets/flower.png",
        "https://img.ohdat.io/loony_face/website/assets/btnbg.png",
        "https://img.ohdat.io/loony_face/website/assets/bgporject.png",
        "https://img.ohdat.io/loony_face/website/assets/m/footbg_m.png",
        "https://img.ohdat.io/loony_face/website/assets/projectNew.png",
        "https://img.ohdat.io/loony_face/website/assets/inpt.png",
        "https://img.ohdat.io/loony_face/website/assets/enter.png",
        "https://img.ohdat.io/loony_face/website/assets/soldout.png",
        "https://img.ohdat.io/loony_face/website/assets/sel.png",
        "https://img.ohdat.io/loony_face/website/assets/mintProcess.png",
        "https://img.ohdat.io/loony_face/website/assets/errorbox.png",
        "https://img.ohdat.io/loony_face/website/assets/m/minput.png",
        "https://img.ohdat.io/loony_face/website/assets/m/menter.png",
        "https://img.ohdat.io/loony_face/website/assets/m/sel_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/mintProcess_m.png",
        "https://img.ohdat.io/loony_face/website/assets/m/errorbox_m.png",
        "https://img.ohdat.io/loony_face/website/assets/videobg.jpg",
        "https://img.ohdat.io/loony_face/website/assets/maxtext.png",
      ];
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.preloadCount++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.preloadCount / imgs.length) * 100);
          this.percent = `${percentNum}%`;
          // console.log(this.percent);
          // console.log(img);
          if (percentNum == 100) {
            this.rolling = false;
          }
        };
      }
    },
    opensea() {
      window.open("https://opensea.io/collection/loonyfaceofficial");
    },
    blur() {
      window.open("https://blur.io/collection/loonyfaceofficial");
    },
  },
  beforeDestroy() {},
};
</script>
<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>
