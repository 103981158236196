import request from '@/utils/request'
import baseURL from '@/utils/baseURL'

// 质押列表
export function list_all_my_loony_face_genesis(query) {
  return request({
    url: baseURL["item"] + `/v2/item/list_all_my_loony_face_genesis`,
    method: "get",
    params: query,
  });
}

